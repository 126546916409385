import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Suchá pokožka</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Suchá pokožka
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="current">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="link">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="link">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="link">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="link">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="link">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="bg skora1">
                  <div className="text full">
                    <div className="text full">
                      <h2>Suchá pokožka</h2>
                      <p className="text left">
                        Suchá pokožka, tzv. xeroza, na rukou, chodidlech či
                        celém těle, je problémem, který může být dočasný či může
                        být příznakem chronické dermatózy, jako např. atopické
                        dermatitidy. Zažíváte často nepříjemný pocit spojený se
                        suchou pokožkou? Zjistěte, který externí či interní
                        faktor způsobuje vysoušení pokožky, a vyberte si vhodný
                        pečující výrobek, který poskytne vaší pokožce nejlepší
                        péči.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Nadměrné vysoušení pokožky může být způsobeno řadou
                        faktorů, jako např.:
                      </p>
                    </div>
                    <div className="text left">
                      <div className="p">
                        <ul>
                          <li>
                            časté mytí a používání nevhodných mycích výrobků,
                          </li>
                          <li>kontakt s chemikáliemi a parfémy,</li>
                          <li>teplotní extrémy,</li>
                          <li>vystavování slunci,</li>
                          <li>slaná voda,</li>
                          <li>studené, suché a větrné počasí,</li>
                          <li>nečistoty či pasivní/aktivní kouření,</li>
                          <li>celkové zdraví, strava, věk či stres.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg">
                  <div className="text full   ">
                    <div className="p">
                      <p>
                        Dehydratace způsobuje hrubnutí a praskání pokožky. Další
                        symptomy nadměrného vysoušení pokožky jsou:
                      </p>
                      <p>&nbsp;</p>
                      <ul>
                        <li>pocit pnutí,</li>
                        <li>nedostatek elasticity,</li>
                        <li>svědění,</li>
                        <li>loupání,</li>
                        <li>praskání.</li>
                      </ul>
                      <p>&nbsp;</p>
                      <p>
                        Tyto symptomy se objevují, když je rohová vrstva
                        epidermu poškozena <br />a nemůže fungovat jako funkční
                        bariéra chránící pokožku před poraněním a ztrátě vody.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <div id="fb_69"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
